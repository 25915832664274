@import "src/themes/__variables.scss";

:local {
  .label {
    color: #C8C8C8;
    cursor: pointer;
    display: block;
    font-size: 13/16 * 1em;
    font-weight: 600;
    margin-bottom: 0.6em;
    text-transform: uppercase;
    letter-spacing: -0.25px;

    @media all and (max-width: $breakpoint-tablet) {
      font-size: 12px;
    }

    &.isFloating {
      background: #FFF;
      display: block;
      font-size: 0.8125em;
      font-weight: 400;
      padding: 0 0.38461538em;
      transition: all 0.25s ease-out;
      text-align: left;
      position: absolute;
      top: -0.66em;
      left: 0.66em;
      z-index: 1;
    }
  }
}
