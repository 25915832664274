
// Containers
$container-main: 1240px;
$container-medium: 768px;

// Responsive breakpoints
$breakpoint-mobile: 767px;
$breakpoint-mobile-sm: 480px;
$breakpoint-tablet: 1023px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-main-container: 1066px;
$breakpoint-desktop: 1200px;


// mixins
@mixin dynamicTextSize($baseWidth, $baseFontSize: 16px, $minWidth: 320px) {
  @media (max-width: $baseWidth) and (min-width: $minWidth) {
    font-size: $baseFontSize / $baseWidth * 100vw;
  }
}

@mixin container-main {
  max-width: $container-main;
  margin: 0 auto;
  padding: 0 30/12+em;

  @media all and (max-width: $breakpoint-mobile) {
      padding: 0 1.5em;
  }

  @media all and (max-width: 375px) {
      padding: 0 1.5em;
  }
}

@mixin container-main-vertical {
  padding-top: 30/12+em;
  padding-bottom: 30/12+em;

  @media all and (max-width: $breakpoint-mobile) {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
  }

  @media all and (max-width: 375px) {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
}

@mixin dynamicTextSize($baseWidth, $baseFontSize: 16px, $minWidth: 320px) {
@media (min-width: $minWidth) and (max-width: $baseWidth) {
  font-size: $baseFontSize / $baseWidth * 100vw;
}
}
